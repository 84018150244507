import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import Button from '../components/Button';
import Subsection from '../components/Subsection';
import styled from 'styled-components';
import { respondTo } from '../util/breakpoints';
import { Body, Ul } from '../util/typography';
import * as colors from '../util/colors';
import { jobCodes } from '../util/data/jobCodes';

/* 
    For documentation purposes - this page is a little bit of a one-off from the design system
*/

// SEO INFORMATION
const pageMeta = {
  title: `Sitemap for KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Health Care Professional Resources`,
  keywords: `keytruda sitemap`,
  description: `Browse the KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) sitemap for faster navigation of the website. View sitemap here.`,
  schemaJsonLD: [
      `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/sitemap/","@type":"MedicalWebPage","name":"Site Map"}`,
      `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/sitemap/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
  ]
}

const jobCode = jobCodes[0].jobCode;

const CustomButton = styled(Button)`
  &.tertiary-dark {
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ${respondTo.md`
          width: fit-content;
          justify-content: flex-start;
      `}
  }
`;

const CustomContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 40px 0px;
  max-width: 280px;

  a {
    margin-bottom: 12px;
  }

  ul { 
    margin-left: 17px;
  li{
    &:first-child {
      margin-bottom: 0;
      a {
        margin-bottom: 0;
      }
    }
  }
  }

  p {
    margin: 10px 0 8px 0;
  }

  ${respondTo.md`
    padding: 10px 30px 40px 0;
    max-width: 347px;
  `}
`;

const List = styled(Ul)`
  li::before {
    top: 18px;
  }
`;

const links = [
  {
    href: '/safety',
    text: 'Safety'
  },
  {
    href: '/dosing',
    text: 'Dosing'
  },
  {
    href: '/patient-support',
    text: 'Patient Support'
  },
  {
    href: '/professional-resources',
    text: 'Resources'
  }
]

const efficacyLinks = [
  {
    href: '/advanced-renal-cell-carcinoma/',
    text: 'Advanced Renal Cell Carcinoma'
  },
  {
    href: '/endometrial-carcinoma/',
    text: 'Advanced Endometrial Carcinoma'
  },
]


const Index = ({location}) => {

  return (
    <DefaultLayout location={ location } name='Site Map' pageMeta={pageMeta} jobCode={jobCode}>
      <Subsection>
        <CustomContainer>
          <CustomButton type='tertiary-dark' to='/'>Home</CustomButton>
          <Body><b>Efficacy</b></Body>
          <List marker={colors.charcoal}>
            { efficacyLinks.map((link) => (
              <li key={link.href}>
                <CustomButton type='tertiary-dark' to={link.href}>{link.text}</CustomButton>
              </li>
            ))}
          </List>
          {links.map((link) => (
            <CustomButton key={link.href} type='tertiary-dark' to={link.href}>{link.text}</CustomButton>
          ))}
        </CustomContainer>
      </Subsection>
    </DefaultLayout>
  )
};

export default Index;
